import * as yup from 'yup'

export const wordCount = (str) => str?.trim().split(/\s+/).filter(Boolean).length

const getQuestionValidation = (q) => {
  const multipleChoiceSchema = (required) => {
    const schema = yup
      .array()
      .of(yup.string())
      .transform((value) => value || [])
      .test(
        'maxSelect',
        `Maximum ${q.maxSelect} options allowed`,
        (value) => !value || value.length <= q.maxSelect,
      )

    return required
      ? schema.min(1, 'This is a required question').required('This is a required question')
      : schema
  }

  const textSchema = (required) => {
    const schema = yup
      .string()
      .test(
        'wordLimit',
        `Please limit your answer to ${q.wordLimit} words`,
        (value) => !value || wordCount(value) <= parseInt(q.wordLimit),
      )
    return required ? schema.required('This is a required question') : schema
  }

  const radioSchema = (required) => {
    const schema = yup.string()
    return required ? schema.required('This is a required question') : schema
  }

  const schemas = {
    multiple_choice: multipleChoiceSchema,
    radio_button: radioSchema,
    text: textSchema,
  }

  return schemas[q.answerType]?.(q.required) || textSchema(q.required)
}

const stringFieldSchema = (required) => {
  const schema = yup.string()
  return required ? schema.required('This is a required question') : schema
}

export const createFormSchema = ({
  email,
  name,
  dateOfBirth,
  school,
  phone,
  address,
  education,
  facebook,
  instagram,
  photo,
  artwork,
  questions,
}) =>
  yup.object().shape({
    email: email?.required
      ? yup.string().email('Invalid email').required('This is a required question')
      : yup.string().email('Invalid email'),
    name: stringFieldSchema(name?.required),
    dateOfBirth: stringFieldSchema(dateOfBirth?.required),
    school: school?.required
      ? yup.object().shape({
          value: yup.string().required('This is a required question'),
          documentId: yup.string(),
        })
      : yup.object().shape({
          value: yup.string(),
          documentId: yup.string(),
        }),
    education: education?.required
      ? yup.object().shape({
          value: yup.string().required('This is a required question'),
          documentId: yup.string(),
        })
      : yup.object().shape({
          value: yup.string(),
          documentId: yup.string(),
        }),
    phone: stringFieldSchema(phone?.required),
    address: stringFieldSchema(address?.required),
    facebook: stringFieldSchema(facebook?.required),
    instagram: stringFieldSchema(instagram?.required),
    photo: stringFieldSchema(photo?.required),
    artwork: stringFieldSchema(artwork?.required),
    ...questions?.reduce(
      (acc, q) => ({
        ...acc,
        [q.documentId]: getQuestionValidation(q),
      }),
      {},
    ),
  })
